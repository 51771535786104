import React from 'react';
import "./Maintain.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faScrewdriverWrench, faSearch } from '@fortawesome/free-solid-svg-icons';
import Layout from '../CommonCompoment/Layout/Layout';

const Maintain = () => {
    return (
        <Layout>
            <div className='maintainSection'>
                <div className="container maintenance-container">
                    <div className="maintenance-icon">
                        <FontAwesomeIcon icon={faScrewdriverWrench} />
                    </div>
                    <h1>We’re under maintenance</h1>
                    <p>We’re currently in beta testing mode and are not accepting general account sign-ups at this time. Check back soon, or reach out to discuss eligibility for early access!</p>
                </div>
            </div>
        </Layout>
    )
}

export default Maintain