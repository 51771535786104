import React, { useEffect, useState } from 'react';
import PriceTable from '../Pricing/PriceTable';
import { Col, Container, Row, Tab, Tabs } from 'react-bootstrap';
import { getAllPackage } from '../../api/package';
import { PackageInterface } from '../CommonInterface/CommonInterface';
import SignUpNavbar from '../CommonCompoment/SignUpNavbar/SignUpNavbar';
import Footer from '../CommonCompoment/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { getCookieValue, getPriceByName, setCookie } from '../CommonInterface/CommonFunction';


const ChoosePlan = () => {
  const navigate = useNavigate();
  const [packagePrice, setPackagePrice] = useState<PackageInterface[] | undefined>();
  const [apiCalled, setApiCalled] = useState(false);
  const [billingType, setBillingType] = useState("Monthly");
  const storedPaymentPlan = JSON.parse(getCookieValue('choosePlan') || '{}');

  useEffect(() => {
    if (!apiCalled) {
      getAllPackage().then((data) => {
        setPackagePrice(data.packages);
        setApiCalled(true);
      })
    }

  }, [apiCalled]);


  const choosePlan = (plan: string) => {
    setBillingType(plan)
    if (storedPaymentPlan) {
      storedPaymentPlan.billingType = plan;
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
  }

  const selectChoosePlan = (plan: string) => {
    if (storedPaymentPlan) {
      storedPaymentPlan.price = plan;
      storedPaymentPlan.billingType = billingType;
      setCookie('choosePlan', JSON.stringify(storedPaymentPlan));
    }
    return navigate(`/registration-account`);
  }

  return (
    <>
      <SignUpNavbar />
      <section className="paymentInfo">
        <Container>
          <Row>
            <Col md={12}>
              <div className="paymentHeading">
                <h1>Secure cloud storage – and so much <br /> more</h1>
                <p>includes Docoteam Sign, Backup, Transfer, Passwords and <br /> Capture at no extra cost.</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="paymentFeature" style={{ marginBottom: "50px" }}>
                <h2>Choose the best plan for your business</h2>
              </div>
            </Col>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col md={10}>
              <div className="coreFeature">
                <Tabs
                  defaultActiveKey="monthly"
                  id="uncontrolled-tab-example"
                  className="priceTab"
                  onSelect={(key: string | null) => {
                    if (key) {
                      choosePlan(key === 'monthly' ? 'Monthly' : 'Annually');
                    }
                  }}
                >
                  <Tab className='monthly' eventKey="monthly" title="Monthly">
                    <Row>
                      <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 0, 1)} standardMax="" plusMin={getPriceByName(packagePrice, 'PLUS', 0, 1)} plusMax="" />
                    </Row>
                  </Tab>
                  <Tab className='yearly' eventKey="yearly" title="Yearly">
                    <Row>
                      <PriceTable selectChoosePlan={selectChoosePlan} packagePrice={packagePrice} billingType={billingType} standardMin={getPriceByName(packagePrice, 'STANDARD', 5, 1)} standardMax={getPriceByName(packagePrice, 'STANDARD', 0, 1)} plusMin={getPriceByName(packagePrice, 'PLUS', 5, 1)} plusMax={getPriceByName(packagePrice, 'PLUS', 0, 1)} />
                    </Row>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  )
}

export default ChoosePlan